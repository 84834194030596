import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordStrengthValidator =
  (): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    // eslint-disable-next-line functional/prefer-readonly-type
    const errors: { [key: string]: boolean } = {};

    // Password length validation
    if (control.value?.length < 15) {
      errors['minLength'] = true;
    }

    // Uppercase letter validation
    if (!/[A-Z]/.test(control.value)) {
      errors['uppercase'] = true;
    }

    // Lowercase letter validation
    if (!/[a-z]/.test(control.value)) {
      errors['lowercase'] = true;
    }

    // Number validation
    if (!/\d/.test(control.value)) {
      errors['number'] = true;
    }

    // Special character validation
    if (!/[!@#$%^&*]/.test(control.value)) {
      errors['specialChar'] = true;
    }

    return Object.keys(errors).length > 0 ? errors : null;
  };
