import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { ToasterService } from '@shared/components/toaster/services/toaster.service';

// INFO: Ezt akkor használjuk HA nincs külön state-je az adott componensnek, vagy akár kettő is van pl: reset-password

export type KeyBasedApiCallStateType = {
  readonly [key: string]: {
    readonly requestStatus: 'init' | 'loading' | 'loaded' | 'error';
    readonly requestError: HttpErrorResponse | null;
  };
};

@Injectable({
  providedIn: 'root',
})
export class ApiCallState {
  readonly #keyBasedApiCallState = signalState<KeyBasedApiCallStateType>({});
  readonly toasterService = inject(ToasterService);

  setLoading = (caller: string): void => {
    patchState(this.#keyBasedApiCallState, {
      ...this.#keyBasedApiCallState(),
      [caller]: { requestStatus: 'loading', requestError: null },
    });
  };

  setLoaded = (caller: string): void => {
    patchState(this.#keyBasedApiCallState, {
      ...this.#keyBasedApiCallState(),
      [caller]: { requestStatus: 'loaded', requestError: null },
    });
  };

  setError = (caller: string, error: HttpErrorResponse | null): void => {
    patchState(this.#keyBasedApiCallState, {
      ...this.#keyBasedApiCallState(),
      [caller]: { requestStatus: 'error', requestError: error },
    });
  };

  reset = (caller: string): void => {
    patchState(this.#keyBasedApiCallState, {
      ...this.#keyBasedApiCallState(),
      [caller]: { requestStatus: 'init', requestError: null },
    });
  };

  isLoading(caller: string): Signal<boolean> {
    return computed(() =>
      caller in this.#keyBasedApiCallState() ? this.#keyBasedApiCallState()[caller].requestStatus === 'loading' : false
    );
  }

  hasError(caller: string): Signal<boolean> {
    return computed(() =>
      caller in this.#keyBasedApiCallState() ? this.#keyBasedApiCallState()[caller].requestStatus === 'error' : false
    );
  }

  error(caller: string): Signal<HttpErrorResponse | null> {
    return computed(() => (caller in this.#keyBasedApiCallState() ? this.#keyBasedApiCallState()[caller].requestError : null));
  }
}
