import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Signal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';
import { ToasterService } from '@shared/components/toaster/services/toaster.service';

// INFO: Ezt akkor használjuk HA van külön state-je az adott componensnek, és oda pedig extendelni kell!

export type ApiCallStateType = {
  readonly requestStatus: 'init' | 'loading' | 'loaded' | 'error';
  readonly requestError: HttpErrorResponse | null;
};

export abstract class AbstractApiCallState {
  readonly #apiCallState = signalState<ApiCallStateType>({ requestStatus: 'init', requestError: null });
  readonly toasterService = inject(ToasterService);

  readonly isLoading: Signal<boolean> = computed(() => this.#apiCallState().requestStatus === 'loading');
  readonly hasError: Signal<boolean> = computed(() => this.#apiCallState().requestStatus === 'error');
  readonly error: Signal<HttpErrorResponse | null> = this.#apiCallState.requestError;

  setLoading = (): void => {
    patchState(this.#apiCallState, { requestStatus: 'loading' });
  };

  setLoaded = (): void => {
    patchState(this.#apiCallState, { requestStatus: 'loaded' });
  };

  setError = (error: HttpErrorResponse | null): void => {
    patchState(this.#apiCallState, { requestStatus: 'error', requestError: error });
  };

  reset(): void {
    patchState(this.#apiCallState, { requestStatus: 'init', requestError: null });
  }
}
