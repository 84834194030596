import { baseEnvironment, Environment } from 'src/environments';

// Development environment
export const environment: Environment = {
  ...baseEnvironment,
  type: 'dev',
  apiUrl: 'https://varkapitanysag-citywalker-cms.dev.trendency.hu/application-api',
  siteUrl: 'https://varkapitanysag.dev.trendency.hu/',
  googleMapsKey: '',
  googleAnalyticsCode: '',
  jotForms: {
    contactUs: '241342665196359',
  },
};
