import { Injectable, signal, WritableSignal } from '@angular/core';
import { ToasterData, ToasterType } from '@shared/components/toaster/models/toaster.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  readonly data: WritableSignal<ToasterData[]> = signal<ToasterData[]>([]);
  private readonly delay = 10000;

  public openToaster(options: Omit<ToasterData, 'type'> & { type?: ToasterType }): void {
    const newToaster: ToasterData = { ...options, type: options.type ? options.type : 'danger', delay: options.delay ?? this.delay };
    this.data.update((toaster) => [...toaster, newToaster]);
  }

  public closeToaster(i: number): void {
    const toasterList: ToasterData[] = this.data();
    toasterList.splice(i, 1);

    this.data.set(toasterList);
  }

  public closeAll(): void {
    this.data.set([]);
  }
}
