import { Environment } from 'src/environments';

export type AvailableLangs = 'hu' | 'en';
export const availableLangs: AvailableLangs[] = ['hu', 'en'];
export type AvailableLocales = 'hu-HU' | 'en-GB';

export const baseEnvironment: Environment = {
  production: false,
  type: 'local',
  httpReqTimeout: 30,
  apiUrl: '',
  siteUrl: '',
  siteName: 'Várkapitányság',
  googleMapsKey: '',
  googleAnalyticsCode: '',
  siteKeyV2: '6LedyOopAAAAANxwdV8RF2ptDn0FV4XZELWCLm5X',
  delay: 1000,
  jotForms: {
    contactUs: '',
  },
};
