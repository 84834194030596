import { Injectable, OnDestroy } from '@angular/core';
import { AbstractApiCallState } from '@shared/utils/call-state/states/abstract-api-call.state';
import { ApiCallState } from '@shared/utils/call-state/states/api-call.state';

@Injectable()
export abstract class ApiCallStateResetBaseComponent<T extends ApiCallState | AbstractApiCallState> implements OnDestroy {
  readonly #state: T;
  readonly apiCallStateKey: string;

  constructor(state: T, caller: string = state.constructor.name) {
    this.#state = state;
    this.apiCallStateKey = caller;
  }

  ngOnDestroy(): void {
    if (this.#state instanceof ApiCallState) {
      this.#state.reset(this.apiCallStateKey);
    } else {
      this.#state.reset();
    }
  }
}
